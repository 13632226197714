@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.boot-camp-filters {
  width: 100%;

  &__meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    gap: spacing.$s;
    padding-bottom: spacing.$m;
  }

  &__filter {
    // NOTE: This should match the break point of the filter component
    @media (min-width: 500px) {
      padding-left: spacing.$s;
      padding-right: spacing.$s;
      margin-bottom: spacing.$s;
    }
  }

  &__selected-filters {
    padding-left: spacing.$s;
    padding-right: spacing.$s;
    margin-top: spacing.$s;
  }

  &__container-inner {
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__container {
    margin: 0 auto;
    max-width: 1144px; // NOTE: This width should match with the list width

    &--with-padding {
      padding-left: spacing.$s;
      padding-right: spacing.$s;
    }
  }

  &__selected-filters {
    margin-bottom: spacing.$s;
  }
}
